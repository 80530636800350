<template>
	<div class="header">
		<div class="header-header header-lightTheme" :style="scrollLength>0?'background: #fff;color: #666666;border-bottom: solid 1px rgb(244,244,244);box-shadow: 0 -1px 0 0 rgb(17 31 44 / 8%);':''">
			<div class="header-container">
				<div class="header-left">
					<a class="header-logo" @click="handleSelect('/');">
						<img src="../assets/logo.png" align="absmiddle"><span :style="scrollLength>0?'color: #0A72BF;':''">蓝莓科技</span>
					</a>
					<img class="wechat-logo" src="../assets/img/img.png"/>
				</div>
				<div class="header-right">
					<a class="header-navItem"  @click="handleSelect('/');"><span :class="flag==='home'?'isAction':''" :style="flag==='home' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">首页</span></a>
					<div class="header-navItem" >
						<span :class="flag==='solution'?'isAction':''"  :style="flag==='solution' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">解决方案</span>
						<div class="header-solutionMenu">
							<a class="header-solutionBlock"  v-for=" item in business" @click="handleSelect(item.path);">
								<div class="header-sIcon">
									<img :src="item.image" width="48" height="48" align="absmiddle" />
								</div>
								<p class="header-sTitle">{{item.title}}</p>
							</a>
						</div>
					</div>
					<a class="header-navItem"  @click="handleSelect('/case');"><span :class="flag==='case'?'isAction':''"  :style="flag==='case' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">客户案例</span></a>
					<a class="header-navItem"  @click="handleSelect('/news');"><span :class="flag==='news'?'isAction':''"  :style="flag==='news' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">新闻动态</span></a>
					<div class="header-navItem">
						<span :class="flag==='us'?'isAction':''"  :style="flag==='us' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">关于我们</span>
						<div class="header-aboutMenu">
							<a class="header-aboutLink"  @click="handleSelect('/company');">公司介绍</a>
							<a class="header-aboutLink"  @click="handleSelect('/agent');">招商加盟</a>
							<a class="header-aboutLink"  @click="handleSelect('/about');">联系我们</a>
						</div>
					</div>
					<a class="header-navItem"  @click="handleSelect('/joinUs');"><span :class="flag==='offer'?'isAction':''"  :style="flag==='offer' && scrollLength>0?'border-bottom: 4px solid  #218BFB':''">招聘信息</span></a>
				</div>
				<div class="header-login"><el-link href="https://www.yituokeapp.com" class="go-login"  target="_blank" :style="scrollLength>0?'color: #666666;':''">去登录/注册</el-link></div>
			</div>
		</div>
	</div>
</template>

<script>

export default{
	name:"Header",
	props:{
		'pageName':String,
		'flag':String,
		},
	data(){
		return{
			scrollLength:0,
			activeIndex: '1',
			activeColor: '#409EFF',
			business:[
			  {title:"易拓客",
			  path:"/ytk",
			  intro:"甄别客户，精准客源，从客源到成单的一体化管理，提高销售业绩。",
			  image:require("@/assets/img/yituoke.png")},
			  {
			  title:"电销外呼防封电话",
			  path:"/phone",
			  intro:"产品实现避开封卡标准，每天拨打电话不封号，在可控的范围内帮助用户实现销售高业绩。",
			  image:require("@/assets/img/fangfengdianhua.png")
			  },
			  {
			  title:"短信群发营销",
			  path:"/message",
			  intro:"产品使用企业级 106 短信平台，支持短信营销、通知，并提供短信在线发送。",
			  image:require("@/assets/img/duanxunqunfa .png")
			  },
			  {
			  title:"电商小程序",
			  path:"http://mini.lanmeihulian.com",
			  intro:"",
			  image:require("@/assets/img/dianshangxiaochengxu.jpg")
			  },
			  {
			  title:"智能名片",
			  path:"/ai",
			  intro:"智能名片代替传统名片，实现带流量的名片，AI+大数据+小程序，智能挖掘客户线索，实现流量变现。",
			  image:require("@/assets/img/zhinengmingpian.png")
			  },
			  {
			  title:"红包裂变",
			  path:"/red",
			  intro:"红包裂变吸引新用户，实现增加流量的活动，微信公众号+小程序智能挖掘客户线索，实现流量转换。",
			  image:require("@/assets/img/red_packet.png")
			  },
			  {
			  title:"拓客活动",
			  path:"/tk",
			  intro:"通过快速分享资源、数据信息、媒体链接，让用户得知产品相关活动，并根据活动选择相关项目，一定程度上实现推广、营销、销售等进度。",
			  image:require("@/assets/img/tuokehuodong.png")
			  },
			  {
			  title:"易拓客SCRM",
			  path:"http://web.yituokeapp.com",
			  intro:"",
			  image:require("@/assets/img/scrm.png")
			  },
			  // {
			  // title:"AI智能外呼机器人",
			  // path:"http://aiphone.lanmeihulian.com/robot/admin/login",
			  // intro:"",
			  // image:require("@/assets/img/outboundbotzhinengwaihujiqiren-3.png")
			  // },
			  {
			  title:"易视App",
			  path:"/ys",
			  intro:"",
			  image:require("@/assets/img/ys.jpg")
			  },
			  {
			  title:"API开放平台",
			  path:"/cloudApi",
			  intro:"",
			  image:require("@/assets/img/api.png")
			  },
			],
			headStyle: {
				background: '#fff',
			}
		}
	},
	created(){
		if(this.pageName == null || this.pageName==""){
			this.scrollLength = document.documentElement.scrollTop || document.body.scrollTop;
		}else{
			this.scrollLength =1;
		}
	   document.addEventListener('scroll', this.getScrollPosition)
	 },
	 beforeDestroy () {
	   document.removeEventListener('scroll', this.getScrollPosition);
	 },
	methods:{
		handleSelect(path) {
			if(path.indexOf("http")!=-1 || path.indexOf("https")!=-1){
				window.open(path, '_blank');
			}else{
				this.$router.push({path:path,query:{t:Date.now()}});
			}
	    },
		getScrollPosition(){
			 // 滚动条距顶部距离
			if(this.pageName == null || this.pageName==""){
				this.scrollLength = document.documentElement.scrollTop || document.body.scrollTop;
			}else{
				this.scrollLength =1;
			}
		}
	}
}
</script>

<style scoped lang="less">
.header{
	.header-header{
		width: 1200px;
		margin: auto;
		height: 64px;
		line-height: 64px;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
		color: #fff;
		// transition: background-color .5s ease-in-out;
		// box-sizing: border-box;
		.header-container{
			width: 1500px;
			margin: auto;
			position: relative;
			display: -webkit-flex; /* Safari */
			display: flex;
			flex-direction:center;
			justify-content:space-around;
			.header-left{
				.header-logo{
					img{
						width: 50px;
						height: 50px;
					}
					span{
						padding-left: 10px;
						font-size: 26px;
						font-weight: 600;
						color: #fff;
					}   
					display: inline-block;
					vertical-align: top;
					box-sizing: border-box;
					cursor: pointer;
					z-index: 99;
				}
				.header-logo:hover{
					cursor: pointer;
					span{
						color: #0A72BF;
					}
				}	
				.wechat-logo{
					margin-left: 30px;
					padding-top: 20px;
					height: 30px;
					width: 150px;
				}	
			}
			
			.header-right{
				.header-navItem{
					display: inline-block;
					vertical-align: top;
					padding: 0 20px;
					font-size: 20px;
					line-height: 64px;
					height: 64px;
					font-weight: 400;
					cursor: pointer;
					position: relative;
					span{
						display: inline-block;
						height: 54px;
					}
					.isAction{
						border-bottom: 4px solid  #ffffff;
					}
					.header-solutionMenu{
						    display: none;
						    width: 870px;
						    height: auto;
						    background-color: #fff;
						    position: absolute;
						    top: 100%;
						    box-sizing: border-box;
						    padding: 18px;
						    left: -23px;
							box-shadow: 0 8px 24px 0 rgb(17 31 44 / 6%);
						.header-solutionBlock{
							width: 200px;
							height: 140px;
							display: inline-block;
							vertical-align: top;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							padding: 24px;
							text-align: center;
							.header-sIcon{
								width: 64px;
								height: 64px;
								margin: auto;
								margin-bottom: 12px;
							}
							.header-sTitle{
								font-size: 18px;
								color: #111f2c;
								line-height: 32px;
								font-weight: 500;
								margin-bottom: 4px;
								-webkit-transition: color .2s;
								-moz-transition: color .2s;
								transition: color .2s;
							}
							.header-sDesc{
								font-size: 14px;
								color: rgba(17,31,44,.56);
								text-align: justify;
								line-height: 22px;
								font-weight: 400;
							}
						}
						.header-solutionBlock:hover{
							background-color: rgb(245,246,249);
							cursor: pointer
						}
					}
					.header-aboutMenu{
						display: none;
						width: 180px;
						background-color:#fff;
						position: absolute;
						top: 100%;
						left: -16px;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						box-sizing: border-box;
						line-height: normal;
						padding: 8px 0;
						box-shadow: 0 8px 24px 0 rgb(17 31 44 / 6%);
						.header-aboutLink{
							display: block;
							text-align: center;
							height: 48px;
							line-height: 48px;
							font-size: 18px;
							color: #111f2c;
							font-weight: 400;
							cursor: pointer;
						}
						.header-aboutLink:hover{
							color: #fff;;
							background-color: #218bfb;
						}
					}
				}
				.header-navItem:hover{
					color: #217bfb;
					span{
						color: #217bfb;
					}
					.isAction{
						border-bottom: 4px solid  #218BFB;
					}
					.header-solutionMenu{
						transition: background-color .5s ease-in-out;
						display:block;
					}
					.header-aboutMenu{
						display:block;
					}
				}
				
			}
			.header-login{
				
				.go-login{
				   color:#ffffff;
				 }	
			}	
			
		}
		
	}
	.header-header.header-lightTheme:hover{
		color: #666666;
		border-bottom: solid 1px rgba(0,0,0,0.2);
		background: #fff;
		box-shadow: 0 -1px 0 0 rgb(17 31 44 / 8%);
		.header-logo{
			cursor: pointer;
			span{
				color: #0A72BF;
			}
		}	
		.header-login{
			.go-login{
				color: #666666;
			}
			.go-login:hover{
				cursor: pointer;
				color: #007AFF;
			}
		}
		
	}	
}	
</style>
