import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import less from 'less'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import MetaInfo from 'vue-meta-info'
import 'babel-polyfill'
import VueLazyload from "vue-lazyload";

//引入接口
import * as api from '@/api/api'

Vue.prototype.$http = api;


Vue.config.productionTip = false

import '@/styles/global.css' // global css

Vue.use(ElementUI,{locale});

Vue.use(less);

Vue.use(VueAwesomeSwiper);

Vue.use(MetaInfo);

Vue.use(VueLazyload);

if (!global._babelPolyfill) {
	require('babel-polyfill');
}

new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){
      document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
