<template>
	<div class="container">
	    <Header  :flag="flag"/>	
		<div class="banner">
			<div class="banner-content">
				<div class="banner-content-l">
					<span class="ytk-title">易拓客</span>
					<br/>
					<span class="ytk-subtitle">帮您轻松获取海量精准客源 为您节约获客成本</span>
					<br/>
					<span class="ytk-intro">易拓客致力于帮助客户从海量数据中发现并提炼精准和有价值的销售线索;并实现对线索的快速触达，精准快速的锁定客户;完成SaaS的销售线索从获取、跟进、触达、转化到留存的整个生命周期的管理。</span>
					<div >
						<el-button type="primary" class="btn-ytk" @click="qrcodeVisible = true">立即体验</el-button>
					</div>
				</div>
				<div class="banner-content-r">
					<div class="buttom-qrcode">
						<span class="app-title">
							扫描二维码 立即下载体验
						</span>
						<br>
						<div class="buttom-ytkios">
							<img src="../assets/ytk_qrcode.png" width="110px" height="110px" />
							<br>
							<span class="buttom-ytkios-text">IOS</span>
						</div>
						<div class="buttom-ytkandroid">
							<img src="../assets/ytk_qrcode.png" width="110px" height="110px" />
							<br>
							<span class="buttom-ytkandroid-text">Android</span>
						</div>
					</div>
				</div>
			</div>
			<el-dialog
			  width="30%"
			  title="扫一扫下载体验"
			  :visible.sync="qrcodeVisible"
			  append-to-body>
			  <div class="ytk-qr-code">
				  <img src="../assets/ytk_qrcode.png" />
			  </div>
			</el-dialog>
		</div>
		<div class="main">
			<div class="ytk-intro">
				 <div class="ytk-intro-text">
					 <span>易拓客简介</span>
					 <br/>
					 <div class="server-border"></div>
					 <br/>
					 <div class="intro-text">
						 易拓客接入高德，百度，腾讯三大地图的数据，为您量身打造全方位地图资源客源搜索平台。<br/>
						 通过“区域+关键词”、“区域+行业”、“附近定位+关键词”等多维度搜索，帮助您高效定位客户目标，提供精准营销线索。此外，还接入工商局1.6亿的数据，美团网，阿里巴巴，慧聪网，企业黄页，百度爱采购等行业网站的数据，数据实时更新，并且可以无限量采集，支持一键导出到通讯录，一键导出表格，一键群发短信，一键拨打电话,一键添加微信， 一键同步抖音、快手等社交平台。让您拓客更简单，更方便，更快捷!
					 </div>
				 </div>
				 <div class="ytk-content">
					 <div class="ytk-box" v-for="item in ytkIntros" :style="{backgroundImage:'url(' + item.image+ ')',backgroundRepeat:'no-repeat',backgroundSize: 'cover'}">
						 <div class="box-title">
							 {{item.title}}
						 </div>
						 <div class="box-content">
							 {{item.intro}}				 
						 </div>
					 </div>
				 </div>
			</div>
		</div>
		<div class="ytk-target">
			<div class="target-title">
				<span class="target-title-text">16大功能</span> 
				<br/>
				<span class="target-subtitle-text">让您轻松实现销售目标，提升目标业绩</span>
			</div>
			<div class="target-content">
				<div class="target-left">
					<div class="target-left-title">
						多方式多渠道获取客户资源
					</div>
					<div class="left-content">
						<div class="left-box" v-for="item in ytkTargets">
							<div class="left-title">{{item.title}}</div>
							<div class="left-intro">
								<span>{{item.intro}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="target-right">
					<div class="ad-box-img">
						<el-carousel height="766px" width="375px">
							 <el-carousel-item v-for="item in ytkIntoImg">
								 <el-image
								   style="width: 100%; height: 100%; cursor:pointer;"
								   fit="fill"
								   interval=1000
								   :src="item"
								 ></el-image>
							 </el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
			<div class="target-content1">
				<div class="target-left1">
					<div class="ad-box-img">
						<el-carousel height="766px" width="375px">
							 <el-carousel-item v-for="item in ytkHyImg">
								 <el-image
								   style="width: 100%; height: 100%; cursor:pointer;"
								   fit="fill"
								   interval=1000
								   :src="item"
								 ></el-image>
							 </el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="target-right1">
					<div class="right1-box" v-for="item in ytkTargets1">
						<div class="right1-title">{{item.title}}</div>
						<div class="right1-intro">
							<span>{{item.intro}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="target-content2">
				<div class="target-left2">
					<div class="left2-box" v-for="item in ytkTargets2">
						<div class="left2-title">{{item.title}}</div>
						<div class="left2-intro">
							<span>{{item.intro}}</span>
						</div>
					</div>
				</div>
				<div class="target-right2">
					<div class="ad-box-img">
						<el-carousel height="766px" width="375px">
							 <el-carousel-item v-for="item in ytkDistryImg">
								 <el-image
								   style="width: 100%; height: 100%; cursor:pointer;"
								   fit="fill"
								   interval=1000
								   :src="item"
								 ></el-image>
							 </el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
		<div class="ytk-message">
			<div class="ytk-message-l">
				<span class="ytk-message-title">方便、快速触达客户</span>
				<div class="ytk-message-content" v-for="item in ytkMiddles">
					<span>{{item.title}}</span>
					<div class="ytk-message-intro">
						{{item.intro}}
					</div>
				</div>
			</div>
			<div class="ytk-message-r">
				<div class="ad-box-content">
					<el-carousel height="766px" width="375px">
						 <el-carousel-item v-for="item in ytkIntoImg1">
							 <el-image
							   style="width: 100%; height: 100%; cursor:pointer;"
							   fit="fill"
							   interval=1000
							   :src="item"
							 ></el-image>
						 </el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="ytk-ad">
			<div class="ytk-main">
				<div class="ad-box">
					<div class="ad-box-content ad-box2">
						<span class="ad-title">营销推广，打造自己的品牌</span>
						<div class="ad-content" v-for="item in ytkMiddles1">
							<span>{{item.title}}</span>
							<div class="ad-intro">
								{{item.intro}}
							</div>
						</div>
					</div>
				</div>
				<div class="ad-box">
					<div class="ad-box-img">
						<el-carousel height="766px" width="375px">
							 <el-carousel-item v-for="item in ytkIntoImg2">
								 <el-image
								   style="width: 100%; height: 100%; cursor:pointer;"
								   fit="fill"
								   interval=1000
								   :src="item"
								 ></el-image>
							 </el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
		<div class="buttom-container">
			 <div class="buttom-content">
				<span>易拓客累计为百万用户提供服务，帮助解决拓客难、销售难的问题</span>  
			 </div>
		</div>
		<div class="buttom">
			<div class="buttom-title">
				 <span class="buttom-title-sub">随时随地享受易拓客提供的服务</span>
				 <br>
				 <span class="buttom-title-sub1">多平台客户端助您获取海量精准客源，完成客户触达、跟进等工作，真正做到随时随地移动办公</span>
			 </div>
			<div class="buttom-content-div">
				<div class="buttom-web">
					<div class="buttom-web-img">
						<img src="https://file.lanmeihulian.com/YTK_WEB.png" width="450px" height="371px"/>
						<br/>
						<span>Web</span>
					</div>
				</div>	
				<div class="buttom-ios">
					<div class="buttom-ios-img">
						<img src="https://file.lanmeihulian.com/YTK_IOS.png" width="230px" height="400x" />
						<br/>
						<span>IOS</span>
					</div>
				</div>
				<div class="buttom-android">
					<div class="buttom-android-img">
						<img src="https://file.lanmeihulian.com/YTK_ANDROID.png" width="230px" height="400x" />
						<br/>
						<span>Android</span>
					</div>
				</div>
			</div>
			<div class="app-title">
				扫描二维码，下载易拓客APP进行体验
			</div>
			<div class="buttom-qrcode">
				<div class="buttom-ytkios">
					<img src="../assets/img/ytkios.png" width="150px" height="150px" />
					<br>
					<span class="buttom-ytkios-text">IOS</span>
				</div>
				<div class="buttom-ytkandroid">
					<img src="../assets/img/ytkandroid.png" width="150px" height="150px" />
					<br>
					<span class="buttom-ytkandroid-text">Android</span>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
	metaInfo: {
		title: '查询企业-查询公司-精准客源-行业客源-拓客话术-拓客名片-提供精准销售线索-[蓝莓科技易拓客]',
		meta: [
		  {
			name: 'keywords',
			content: '查询企业,查询公司,精准客源,行业客源,拓客话术,拓客名片,提供精准销售线索,蓝莓科技易拓客'
		  },
		  {
			name: 'description',
			content: '易拓客大数据10000+企业数据，三大地图精准的搜索企业，强大的企业查询工具，公司查询信息一览无遗，精准销售线索为您降低销售成本。'
		  }
		]
	},
	name: 'Ytk',
	components: {
		Header,
		Footer
	},
    data () {
      return {
		  flag:'solution',
		  qrcodeVisible:false,
		  ytkIntros: [
			 {
				image: "https://file.lanmeihulian.com/YTK_1.png",
				title: '数据资源多' ,
				intro: '拥有庞大数据来源，数据进行深度挖掘；支持数据网格处理，精准用户群体，甄别客户源头，销售预测模型。'
			 }, 
			 {
				image: "https://file.lanmeihulian.com/YTK_2.png",
				title: '获客渠道多' ,
				intro: '拥有多方获客渠道，客户信息真实有效；支持数据订阅处理，筛选意向客户，明确客户目标，提供营销策略。'
			 }, 
			 {
				image: "https://file.lanmeihulian.com/YTK_3.png",
				title: '采集方式多' ,
				intro: '拥有成熟采集方式，产品实现内部互联；支持模式识别处理，共享获客资源，数据实时更新，同步产品信息。'
			 },
			 {
				image: "https://file.lanmeihulian.com/YTK_3.png",
				title: '筛选维度多' ,
				intro: '拥有完善结构体系，筛选真实潜在维度；支持多方多维筛选，准确获取客源，客源质量筛选，筛选优质客户。'
			 } 
		  ],
		  ytkTargets: [
			 {
				title: '1、关键字客源' ,
				intro: '通过接入高德、百度、腾讯三大地图的数据，根据公司名字，店名，经营范围等多维度采集数据，地图上的数据审核严格，做到1 00%精准(除非老板不做了)，让您在短时间内得到最多，最精准的精准客源。'
			 }, 
			 {
				title: '2、全国企业（工商数据）' ,
				intro: '通过接入天眼查，企查查等多平台的工商局数据，全国1 .6亿工商数据， 支持法人姓名， 有无手机号，有无座机号，公司经营状态，成立时间，成立资本，企业类型，有无商标，有无专利，有无软著，有无域名，有无法律风险等全方位多维度的筛选条件，让数据更丰富，更精准!'
			 }, 
			 {
				title: '3、附近客源' ,
				intro: '通过接入高德、百度、腾讯三大地图的数据，自动定位您当前位置，或者您选择全国各地任意一个位置， 根据您输入的关键词和指定的距离范围(如附近5公里)进行数据采集，让您在短时间内得到最多，最精准的精准客源。'
			 }
		   ],
		  ytkTargets1: [
			 {
				title: '4、运营商客源' ,
				intro: '易拓客和中国联通、电信、移动三大运营商达成战略合作，借助运营商PB级的大数据库，推出“区域+号码头+号码段”的同城客户手机号。让您在同城营销中比同行优先享用海量意向客户。'
			 } ,
			 {
				title: '5、企业黄页客源' ,
				intro: '企业黄页是国内知名的B2B平台，汇聚国内外大量的厂家，批发商，经销商，行业覆盖成产，制造，服务业等，提供海量全国各地各行业海量资源!拥有海量意向客户资源，让您拓客更快一步!'
			 },
			 {
				title: '6、美团网客源' ,
				intro: '美团网是国内用户量最大的o2o平台，汇聚千万服务行业商家，餐饮，酒店，旅游等行业商家，让您随心所欲地找餐饮，酒店，旅游上下游客户资源，精准客源快人一步!'
			 }
		   ],
		  ytkTargets2: [
			  {
				title: '7、行业客源' ,
				intro: '通过接入高德、百度、腾讯三大地图的数据，提供全行业各种行业分类，支持您—键直接选择想要的行业，快速采集到精准客源。'
			  } ,
			 {
				title: '8、慧聪网客源' ,
				intro: '慧聪网是国内最早的B2B平台之一， 汇聚国内外大量的厂家，批发商，经销商，行业覆盖成产、制造、服务业等，提供海量全国各地各行业海量资源!拥有海量意向客户资源，让您拓客快人一步!'
			 } ,
			 {
				title: '9、最新企业（工商数据）' ,
				intro: '通过接入天眼查，企查查等多平台的工商局数据，全国1.6亿工商数据，支持跟进指定注册时间，法人姓名 ，有无手机号，有无座机号，公司经营状态，成立时间，成立资本，企业类型，有无商标，有无专利，有无软著，有无域名，有无法律风险等全方位多维度的筛选条件，让数据更丰富，更精准!是财会、知识产权等公司的拓客法宝!'
			 } 
		    ],
		ytkMiddles: [
			{
				title: '10、导出通讯录、表格' ,
				intro: '采集到海量客户资源之后，支持一-键导出到您的手机通讯录，支持导出EXCEL表格，打印名单等。'
			}, 
			{
				title: '11、一键添加微信' ,
				intro: '将采集到了客户导入手机通讯录，微信会自动识别可以添加的好友，支持一键添加微信，对方会显示通讯录好友添加，通过率会更高！'
			}, 
			{
				title: '12、短信群发' ,
				intro: '采集到海量客户资源之后，支持一键勾选客户，支持自由添加号码，支持导入手机通讯录号码，使用106专业营销短信发送，数量不限制，不封号，费用低(每条6分钱)，让您的产品和服务传达到海量意向客户手机上'
			}, 
			{
				title: '13、客户管理' ,
				intro: '各种渠道采集得到客户，通过电话，微信等渠道沟通之后，可以把意向客户转为客户，支持备注记录客户状态，支持记录微信昵称，自定义标签，电话号码，支持每一次跟进记录编辑，-键拨打电话，复制号码等，让您更方便高效管理客户信息!'
			}, 
		],
		ytkMiddles1: [
			 {
				title: '海报生成器' ,
				intro: '易拓客设计部每天提供大量主题的精美海报，不同主题，每天热点，不同风格的海报，提供海量模板，支持在线编辑生成属于自己的海报，打造属于自己的品牌，让您的企业与众不同!'
			 }, 
			  {
				title: '易拓客商城' ,
				intro: '易拓客商城是专门为各用户、商家、经销商、批发商等设计的一款小程序商城，买家申请入驻商城，直接在手机微信小程序管理所有商品，发货，查看物流，收款，提现等信息，APP百万用户和微信数亿用户可以直接在线下单!'
			  },
			 {
				title: '供需广场' ,
				intro: '平台百万用户，提供发布各行业供需，系统通过AI人工智能精准推送到意向客户手机上，同时支持信息同步到微信朋友圈，让您广告信息无处不在！'
			 }, 
		],
		ytkIntoImg: [
		  "https://file.lanmeihulian.com/u22.png",
		  "https://file.lanmeihulian.com/u23.png",
		  "https://file.lanmeihulian.com/u24.png"
		],
		ytkHyImg: [
		  "https://file.lanmeihulian.com/u25.png",
		  "https://file.lanmeihulian.com/u26.png",
		  "https://file.lanmeihulian.com/u27.png"
		],
		ytkDistryImg: [
		  "https://file.lanmeihulian.com/u28.png",
		  "https://file.lanmeihulian.com/u29.png",
		  "https://file.lanmeihulian.com/u30.png"
		],
		ytkIntoImg1: [
		  "https://file.lanmeihulian.com/u97.png",
		  "https://file.lanmeihulian.com/u98.png",
		  "https://file.lanmeihulian.com/u99.png",
		  "https://file.lanmeihulian.com/u100.png",
		  "https://file.lanmeihulian.com/u101.png"
		],
		ytkIntoImg2: [
			"https://file.lanmeihulian.com/u105.png",
			"https://file.lanmeihulian.com/u106.png",
			"https://file.lanmeihulian.com/u107.jpg"
		]
		}
	}
}
</script>

<style scoped lang="less">
	.ytk-qr-code{
		text-align: center;
		img{
			width:100%;
			height: 100%;
		}
	}
	.banner{
		padding-top: 80px;
		height: 450px;
		background-image: url(https://file.lanmeihulian.com/ytk-banner1.jpg);
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		.banner-content{
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			width: 1200px;
			margin: auto;
			.banner-content-l{
				width: 600px;
				.ytk-title{
					display: inline-block;
					font-size: 24px;
					color: #ffffff;
					margin-top: 45px;
				}
				.ytk-subtitle{
					display: inline-block;
					margin-top: 18px;
					width: 350px;
					font-size: 28px;
					color: #ffffff;
					line-height: 40px;
				}
				.ytk-intro{
					display: inline-block;
					margin-top: 16px;
					width: 600px;
					font-size: 16px;
					color: #ffffff;
					line-height: 35px;
				}
				.el-button{
					font-size: 18px !important;
				}
				.btn-ytk{
					margin-top: 30px;
					height: 50px;
					width: 200px;
					color: #FFFFFF;
					span{
						font-size: 16px;
					}
				}
			}
			.banner-content-r{
				width: 600px;
				display: flex;
				display: -webkit-flex;
				flex-direction:row;
				justify-content:flex-end;
				.buttom-qrcode{
					width: 280px;
					// margin: auto;
					margin-top: 180px;
					height: 170px;
					text-align: center;
					display: flex;
					display: -webkit-flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content:flex-end;
					.app-title{
						display: inline-block;
						margin-bottom: 20px;
						width: 280px;
						font-size: 16px;
						font-weight: 600;
						color: #fff;
						text-align: center;
					}
					.buttom-ytkios{
						width: 130px;
						.buttom-ytkios-text{
							display: inline-block;
							margin-top: 10px;
							font-size: 20px;
							color: #fff;
							text-align: center;
						}
					}
					.buttom-ytkandroid{
						width: 130px;
						.buttom-ytkandroid-text{
							display: inline-block;
							margin-top: 10px;
							font-size: 20px;
							color: #fff;
							text-align: center;
						}
					}
				}
			}
		}
	}
	.main{
		text-align: center;
		width: 1200px;
		margin: auto;
		margin-top: 70px;
		.ytk-intro{
			margin-top: 60px;
			height: 750px;
			.ytk-intro-text{
				height: 230px;
				span{
					font-size: 40px;
					font-weight: 600;
				}
				.server-border{
					width: 100px;
					background-color: #409EFF;
					height: 8px;
					border-radius: 5px;
					margin: auto;
					margin-top: 20px;
				}
				.intro-text{
					padding-top: 30px;
					padding-left: 10px;
					padding-right: 10px;
					text-align: left;
					margin: auto;
					font-size: 16px;
					line-height: 30px;
					color: #131313;
				}
			}
			.ytk-content{
				margin-top: 40px;
				height: 440px;
				display: flex;
				display: -webkit-flex;
				flex-direction:row;
				flex-wrap:wrap;
				justify-content:center;
				.ytk-box{
					margin: 10px 10px 10px 10px;
					height: 180px;
					width: 560px;
					background-color: #F2F2F2;
					padding: 10px;
					.box-title{
						font-size: 20px;
						font-weight: 600;
						line-height: 60px;
						color: #217bfb;
						text-align: left;
						padding-left: 20px;
					}
					.box-content{
						font-size: 18px;
						line-height: 30px;
						text-align: left;
						color: #555555;
						padding-left: 20px;
						width: 380px;
					}
				}
				.ytk-box:hover{
					box-shadow: 5px 5px 5px gray;
					.box-title{
						color: #1052A1;
					}	
				}
			}
		}
	}
	.ytk-target{
		margin-top: 40px;
		height:auto;
		padding-bottom: 60px;
		background-color: #f7f7f7;
		.target-title{
			padding-top: 63px;
			text-align: center;
			.target-title-text{
				font-size: 40px;
				font-weight: 600;
				color: #131313;
			}
			.target-subtitle-text{
				font-size: 30px;
				color: #131313;
				line-height: 70px;
			}
		}
		.target-content{
			width: 1200px;
			margin: auto;
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left{
				width: 600px;
				.target-left-title{
					padding-top: 10px;
					font-size: 30px;
					color: #217bfb;
				}
				.left-content{
					margin-top: 70px;
					.left-box{
						width: 560px;
						height: 220px;
						margin: 20px 20px 20px 20px;
						.left-title{
							line-height: 45px;
							font-size: 20px;
							font-weight: 600;
							color: rgba(18, 139, 251, 0.8);
						}
						.left-intro{
							width: 560px;
							text-align: left;
							padding: auto;
							padding-top: 20px;
							span{
								font-size: 16px;
								line-height: 35px;
							}
						}
					}
				}
			}
			.target-right{
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
		}
		.target-content1{
			width: 1200px;
			margin: auto;
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left1{
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
			.target-right1{
				margin-top: 80px;
				width: 600px;
				.right1-box{
					width: 560px;
					height: 220px;
					margin: 20px 20px 20px 20px;
					.right1-title{
						line-height: 45px;
						font-size: 20px;
						font-weight: 600;
						color: rgba(18, 139, 251, 0.8);
					}
					.right1-intro{
						width: 560px;
						text-align: left;
						padding: auto;
						span{
							font-size: 16px;
							line-height: 35px;
						}
					}
				}
			}
		}
		.target-content2{
			width: 1200px;
			margin: auto;
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left2{
				width: 600px;
				.left2-box{
					width: 560px;
					height: 220px;
					margin: 20px 20px 20px 20px;
					.left2-title{
						line-height: 45px;
						font-size: 20px;
						font-weight: 600;
						color: rgba(18, 139, 251, 0.8);
					}
					.left2-intro{
						width: 560px;
						text-align: left;
						padding: auto;
						span{
							font-size: 16px;
							line-height: 35px;
						}
					}
				}
			}
			.target-right2{
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
			
		}
	}
	.ytk-message{
		margin-top: 73px;
		display: flex;
		display: -webkit-flex;
		flex-direction:row;
		justify-content:center;
		flex-wrap:wrap;
		height: auto;
		.ytk-message-l{
			width: 600px;
			.ytk-message-title{
				font-size: 30px;
				color: #217bfb;
			}	
			.ytk-message-content{
				margin-top: 40px;
				span{
					line-height: 45px;
					font-size: 20px;
					font-weight: 600;
					color: rgba(18, 139, 251, 0.8);
				}
				.ytk-message-intro{
					margin-top: 20px;
					width: 520px;
					text-align: left;
					font-size: 16px;
					line-height: 35px;
				}
			}
		}
		.ytk-message-r{
			text-align: center;
			width: 600px;
			margin-top: 70px;
			.ad-box-content{
				width: 400px;
				margin: auto;
			}
		}
	}	
	.ytk-ad{
		margin-top: 60px;
		height:auto;
		padding-bottom: 60px;
		background-color: #f7f7f7;
		.ytk-main{
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			justify-content:center;
			flex-wrap:wrap;
			height: auto;
			margin-bottom: 40px;
			.ad-box{
				margin-top: 80px;
				height: 800px;
				width: 600px;
				.ad-box-content{
					width: 600px;
					.ad-title{
						font-size: 28px;
						line-height: 40px;
						font-weight: 600;
					}
					.ad-content{
						margin-top: 40px;
						span{
							line-height: 45px;
							font-size: 20px;
							font-weight: 600;
							color: rgba(18, 139, 251, 0.8);
						}
						.ad-intro{
							width: 520px;
							font-size: 16px;
							line-height: 35px;
							padding-top: 20px;
						}
						
					}
				}
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
		}
	}
	.buttom-container{
		height: 200px;
		background-image: url(../assets/img/zs_img2.png);
		background-repeat: no-repeat;
		margin: auto;
		.buttom-content{
			height:100%;
			display:table;
			width:100%;
			span{
				font-size: 38px;
				color: white;
				font-weight: 600;
				font-family: ;
				display: table-cell;
				text-align: center;
				vertical-align: middle;
			};
		}
	}
	.buttom{
		margin-top: 40px;
		width: 1200px;
		margin: auto;
		.buttom-title{
			margin-top: 60px;
			height: 200px;
			text-align: center;
			.buttom-title-sub{
				font-size: 33px;
				font-weight: 600;
				line-height: 100px;
				color: #131313;
			}
			.buttom-title-sub1{
				font-size: 20px;
				// font-weight: 600;
				line-height: 40px;
				color: #131313;
			}
		}
		.buttom-content-div{
			height: 600px;
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:center;
			.buttom-web{
				width: 600px;
				height:550px;
				span{
					width: 500px;
					display: inline-block;
					text-align: center;
					font-size: 16px;
					font-weight: 600;
				}
				.buttom-web-img{
					margin-top: 20px;
					span{
						margin-top: 50px;
						width: 450px;
						display: inline-block;
						text-align: center;
						font-size: 20px;
						font-weight: 600;
					}
				}
			}
			.buttom-ios{
				width: 300px;
				height:550px;
				span{
					margin-top: 20px;
					width: 230px;
					display: inline-block;
					text-align: center;
					font-size: 20px;
					font-weight: 600;
				}
				.buttom-ios-img{
					margin-top: 20px;	
				}
			}
			.buttom-android{
				width: 300px;
				height:550px;
				span{
					margin-top: 20px;
					width: 250px;
					display: inline-block;
					text-align: center;
					font-size: 20px;
					font-weight: 600;
				}
				.buttom-android-img{
					margin-top: 20px;	
				}
			}
		}
		.app-title{
			font-size: 26px;
			color: #131313;
			text-align: center;
		}
		.buttom-qrcode{
			margin-top: 42px;
			height: 300px;
			text-align: center;
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:center;
			.buttom-ytkios{
				width: 400px;
				.buttom-ytkios-text{
					display: inline-block;
					margin-top: 20px;
					font-size: 20px;
					color: #131313;
					text-align: center;
				}
			}
			.buttom-ytkandroid{
				width: 400px;
				.buttom-ytkandroid-text{
					display: inline-block;
					margin-top: 20px;
					font-size: 20px;
					color: #131313;
					text-align: center;
				}
			}
		}
	}
</style>
