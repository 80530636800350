<template>
	<div class="container">
		<div class="footer">
			<el-row>
				<el-col :span="5"><div class="grid-content bg-purple footer-left">
					<img src="../assets/logo.png" />
					<br/>
					<span class="logo-title">蓝莓科技</span>
				</div></el-col>
			  <el-col :span="14"><div class="grid-content bg-purple-light footer-mudle">
				  <div class="solution">
					  <ul ><li class="li-line"  @click="handleSelect('/ytk');">易拓客</li><li class="li-phone"  @click="handleSelect('/phone');">电销外呼防封电话</li><li class="li-msg"  @click="handleSelect('/message');">短信群发营销</li><li class="li-line"  @click="handleSelect('/ai');">智能名片</li><li class="li-line" @click="handleSelect('/tk');">拓客活动</li></ul>
				  </div>
				  <div class="custom">
					  <ul ><li class="li-line" @click="handleSelect('/case');">客户案例</li><li class="li-line" @click="handleSelect('/news');">新闻动态</li><li class="li-line" @click="handleSelect('/company');">公司介绍</li><li class="li-line" @click="handleSelect('/agent');">招商加盟</li><li class="li-line" @click="handleSelect('/about');">联系我们</li></ul>
				  </div>
				  <div class="copyright">
					 <span>Copyright2017 - 2022 广西南宁蓝莓网络科技有限公司  </span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010702001794" target="_blank"><img src="https://yh.lanmeihulian.com/static/app/version/batb.png">  桂ICP备17001771号-1</a>
				  </div>
			  </div>
			  </el-col>
			  <el-col :span="4"><div class="grid-content bg-purple footer-right">
					<ul>
						<!-- <li class="all-line">全国热线 <span class="line-phone">0771-</span></li>
						<li class="work-time">周一到周五：9:00-18:00</li>
						<li class="work-time">周六：9:00-17:30</li> -->
						<li class="qrcode">
							<div class="qrcode1">
								<img src="https://file.lanmeihulian.com/weChat-logo.jpg"/>
								<div class="qr-text">关注蓝莓科技</div>
							</div> 
							<!-- <div class="qrcode2">
								<img src="../assets/code.jpeg"/>
								<div class="qr-text">关注蓝莓科技</div>
							</div> -->
						</li>
					</ul>
			   
			  </div></el-col>
			</el-row>
		</div>
		<div class="alert-text">
			<el-alert class="warining"
						title="使用蓝莓科技产品期间，不得危害国家安全、泄露国家秘密，不得侵犯国家社会集体和公民的合法权益。
		蓝莓科技产品禁止用于含有木马、病毒、色情、赌博、诈骗等违法违规业务。一经发现，蓝莓科技有权立即停止服务，并协助相关行政执法机关进行清查。"
						type="warning"
						    center
						    show-icon>
			</el-alert>	
		</div>
	</div>
</template>

<script>
export default{
	name:"Footer",
	methods:{
		handleSelect(path) {
			this.$router.push({path:path,query:{t:Date.now()}});
	    },
	}
}
</script>

<style scoped lang="less">
	.container{
		padding-top:20px;
		background-color: #10151f;
		height: 150px;
		// display: -webkit-flex; /* Safari */
		// display: flex;
		// justify-content:center;
		.footer{
			background-color: #10151f;
			width: 1500px;
			.footer-left{
				text-align: center;
				img{
					width: 80px;
					height: 80px;
				}
				.logo-title{
					color: #FFFFFF;
					font-size: 26px;
					font-weight: 600;
				}
			}
			.footer-mudle{
				display: -webkit-flex; /* Safari */
				display: flex;
				flex-direction:column;
				.solution,.custom{
					margin: auto;
					display:table;
					height: 50px;
					.jie{
						font-size: 20px;
						color: #FFFFFF;
						width: 140px;
						opacity: .87;
					}
					
					.li-line{
						opacity: 0.7;
						font-size: 16px;
						width: 100px;
						color: #fafafa;
						text-align: center;
					}
					.li-phone{
						opacity: 0.7;
						font-size: 16px;
						width: 180px;
						color: #fafafa;
						text-align: center;
					}
					.li-msg{
						opacity: 0.7;
						font-size: 16px;
						width: 150px;
						color: #fafafa;
						text-align: center;
					}
					ul{
						display:table-cell; 
						vertical-align:middle;
						text-align:center;
						li{
							float: left;
						}
						li:hover{
							color: #409EFF;
							cursor: pointer;
						}
					}
				}
				.copyright{
					opacity: 0.7;
					height: 30px;
					text-align: center;
					padding-top: 20px;
					color: #fafafa;
				}	
			}
			.footer-right{
				color: #fafafa;
				display: -webkit-flex; /* Safari */
				display: flex;
				justify-content:center;
				.all-line{
					opacity: .87;
					font-size: 18px;
					color: #fafafa;
					font-weight: 400;
					margin-bottom: 20px;
					line-height: 25px;
				}
				.line-phone{
					padding-left: 10px;
					line-height: 33px;
					font-size: 24px;
					color: #fff;
					margin-bottom: 12px;
				}
				.work-time{
					opacity:.6;
					font-size: 14px;
					color: #fafafa;
					font-weight: 400;
					line-height: 20px;
					margin-bottom: 4px;
				}
				.qrcode{
					margin-top: 10px;
					display: flex;
					flex-direction: row;
					.qrcode1,.qrcode2{
						margin-right: 20px;
					}
				}
				img{
					width: 100px;
					height: 100px;
				}
				.qr-text{
					font-size: 14px;
					width: 100px;
					text-align: center;
				}
			}
		}
		.alert-text{
			background-color: #10151f;
			.warining{
				position: fixed;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 960px;
				height: 70px;
				background-color: #f9f5e5;
				width: 1000px;
				height: 90px;
				margin: auto;
				font-size: 18px;
				color: #83796e;
			}
		}
	}
</style>
