<template>
  <div class="home">
    <Header ref="header"  :flag="flag" />
	<div class="banner-banner">
	  <swiper class="swiper" :options="swiperOption"  v-if="carousels.length">
		    <swiper-slide v-for=" (item,index) in carousels" :data-swiper-slide-index="index" >
			 <img alt="蓝莓科技" width="100%" v-if="item.bannerType==1" :src="item.imageUrl" />
		     <video width="100%" v-if="item.bannerType==2" autoplay muted loop preload="auto" :src="item.imageUrl"></video>
			</swiper-slide>
		   <div ref="pagination" id="swiper-pagination" class="swiper-pagination" slot="pagination"></div>
		   <div class="swiper-button-prev btn-prev" slot="button-prev"></div>
		   <div class="swiper-button-next" slot="button-next"></div>
	   </swiper>
	   <div class="ytk-go-div">
	   	 <el-link href="https://www.yituokeapp.com/" :underline="false" target="_blank" class="ykt-text">易拓客网页版   去登录></el-link>
	   </div>
	</div>
	
	<div class="top-content">
		<div class="top-div">
			<div class="top-div-left">
			  <span class="top-left-title">全行业精准客源，获客-触达-成交 全流程智能销售</span>
			  <br/>
			  <span class="top-left-subtitle">易拓客海纳行业数据，提供精准客源，一款致力寻找客源目标的专业产品</span>
			  <div>
				   <el-button type="primary" size="small" class="btn_ty" @click="qrcodeVisible = true">我要体验</el-button>
			  </div>
			</div>
			<div class="top-div-right">
				<img src="../assets/img/ytk_m5.png" />
			</div>
		</div>
		 <el-dialog
		  width="30%"
		  title="扫一扫下载体验"
		  :visible.sync="qrcodeVisible"
		  append-to-body>
		  <div class="ytk-qr-code">
			  <img src="../assets/ytk_qrcode.png" />
		  </div>
		</el-dialog>
	</div>
	<div class="server-div">
		<div class="server-div-top">
			<span class="server-title">我们的服务</span>
			<br>
			<div class="server-border"></div>
		</div>
		<div class="server-div-middle">
			<span class="middle-title">我们将会致力为您提供全套服务，首先从获取客户、提炼客户、甄选客户、确定客户；而后客户触达、客户管理、客卢服务；直至营销推广、营销管理、营销售后的完整的服务体系流程。帮助您降低销售成本，节约营销模式出纳；提供销售平台，提升销售业绩</span>
			<br/>
			<div class="server-middle">
				<div class="server-middle-left">
					<img src="../assets/img/index_1.png" >
					<div class="text">
						<span class="text-s">业务覆盖范围</span><br/>
						<span class="text-style">全国各地</span>
					</div>
				</div>
				<div class="server-middle-right">
					<img src="../assets/img/index_2.png" >
					<div class="text">
						<span class="text-s">服务企业和客户</span><br/>
						<span class="text-style">100万+</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="main">
		<div class="main-div1">
			<div class="title">
				<span class="title-lm">蓝莓科技产品形成流量矩阵为营销创造更多可能</span>
				<br/>
				<div class="title-text">公司旗下产品内联，形成巨大流量矩阵，数据互联资源共享，形成完整数据链，提供专业团队，想用户所想，实现全方位服务流程。</div>
			</div>
			<div class="images-list">
				<div class="b-box" v-for="item in business1"
				 :style="{backgroundImage:'url(' + item.image+ ')', 
				         backgroundRepeat:'no-repeat',backgroundSize: 'cover'}" 
				 @click="businessSelect(item.url);"		 
				 >
				 <div class="b-box-title">
					 <span class="title">{{item.title}}</span>
					 <br/>
					 <div class="b-box-border"></div>
				 </div>
				 <span class="b-box-content">{{item.intro}}</span>
				</div>
			</div>
			<div class="images-list">
				<div class="l-box" v-for="item in business2"
				:style="{backgroundImage:'url(' + item.image+ ')',
				        backgroundRepeat:'no-repeat',backgroundSize: 'cover'}" 
				@click="businessSelect(item.url);"		
				>
				<div class="b-box-title">
					 <span class="title">{{item.title}}</span>
					 <br/>
					 <div class="b-box-border"></div>
				</div>
				<span class="b-box-content">{{item.intro}}</span>
				</div>
			</div>
		</div>
		<div class="main-div2">
			<div class="title">
				蓝莓科技拓客集群，倾心打造数字化营销模式，为销售赋能；倾力塑造行业品牌形象，为企业加持
			</div>
			<div class="ti-div">
				<div class="ti-product-01">
					<div class="ti-title-01">
						<span class="ti-title">产品推广、甄选客户</span><br/>
						<span class="ti-text">（易拓客、智能名片、供需广场、营销商城）</span>
					</div>
					<div class="ti-centent-01">
						<span>
						多方信息渠道、亿级客源数据，为您提供海量精准客户信息；结合多款营销推广方式，提供配套销售平台，助您提升销售业绩
						</span>
						<img src="../assets/img/tyy_1.png" />
					</div>
				</div>
				<div class="ti-product-01">
					<div class="ti-title-02">
						<span class="ti-title">触达客户</span><br/>
						<span class="ti-text">（电销外呼、短信群发）</span>
					</div>
					<div class="ti-centent-02">
						<span>	多种方式触达客户，其中支持MPP，并实现客户数据高效查询、快速锁定、操作便捷；不错漏任何潜在商机，不错过任何意向客户					
						</span>
						<img src="../assets/img/tyy_2.png" />
					</div>
				</div>
				<div class="ti-product-01">
					<div class="ti-title-03">
						<span class="ti-title">管理客户</span><br/>
					</div>
					<div class="ti-centent-03">
						<span>全套服务流程：从线索到成单的跟进过程，至筛选目标客户，到营销模式的推进，从而提高销售业绩					
						</span>
						<img src="../assets/img/tyy_3.png" />
					</div>
				</div>
				<div class="ti-product-01">
					<div class="ti-title-04">
						<span class="ti-title">成单</span><br/>
					</div>
					<div class="ti-centent-04">
					<span>销售业绩 = 客户数量 * 价值				
					</span>
					<img src="../assets/img/tyy_4.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="sales-div1">
		<div class="sales-div1-content">
			<div class="content-left">
				<span class="sales-title">蓝莓科技产品专为助力销售而生</span>
				<div class="sales-content">
					蓝莓科技提供从推广、甄选、计算、获客、触达、跟进、转化、激活、客户裂变的销售，拥有一套完整的服务流程体系。<br/>
					其中产品“易拓客”主要为用户提供多渠道多维度的客源信息，确保客户数据精准性、真实性；精益求精，可在易拓客中一键导出潜在客户通讯录或者Excel表格，并通过产品内联利用电销外呼防封电话、短信群发助手等触达方式进行MPP触达。<br/>
					前期在产品中快速完成客户的初步筛选，中期利用客户管理功能实现意向客户后续的跟进，完成转化；后期主要可以利用供需广场、智能名片、活动商城等渠道推广旗下的服务或产品，将公域流量引到旗下流量池，打造旗下的私域流量。我们的产品宗旨“帮助客户降低获客成本，节约营销模式出纳；提高工作效率，提供销售平台，提升销售业绩，树立企业品牌”。
				</div>
				<div class="buttom-btn">
					<span class="btn1" >
						<el-button class="btn-style" round @click="goToYTK()">活动商城</el-button>
					</span>
					<br/>
					<span class="btn2" >
						<el-button class="btn-style" round @click="goToAi()">智能名片</el-button>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="sales-div">
		<div class="select-us">
			<div class="select-us-title">
				选择我们的理由
			</div>
			<div class="select-us-content">
				<div class="reason-box" v-for="item in reasons">
					<div class="reason-box-top">
						<div class="reason-box-img">
							<img :src="item.image"/>
						</div>
						<div class="reason-title">
							<span class="title">{{item.title}}</span>
						</div>
					</div>
					
					<div class="reason-intro">
						{{item.intro}}
					</div>
				</div>
			</div>
		</div>
		
		<div class="evaluation">
			<div class="evaluate-title">
				<span class="title">“对用户负责，对产品加持”</span>
				<br/>
				<span class="subtitle">设计对用户有价值的产品才是我们的价值导向</span>
			</div>
			<div class="evaluate-content">
				<div class="review-box" v-for="item in evaluation">
					<div class="review-img">
						<img :src="item.image" />
					</div>
					<span class="review-title">{{item.title}}</span>
					<br>
					<span class="review-intro">{{item.intro}}</span>
				</div>
			</div>
			<div class="more">
				<el-button round class="more-btn" @click="caseMore()">更多案例>></el-button>
			</div>
		</div>
		
		<div class="perform">
			<div class="perform-title">蓝莓科技产品协助客户创造更高销售业绩</div>
			<div class="perform-content">
				<img src="../assets/img/tj.png" />
				<div class="perform-item">
					<ul class="perform-item-ul">
						<li>拓客效率提升</li>
						<li>触达效率提升</li>
						<li>管理效率提升</li>
						<li>销售业绩提升</li>
						<li>获客成本下降</li>
					</ul>
				</div>
				<!-- <div class="perform-box" v-for="item in performs">
					<div class="box-schedule">
						<el-progress type="circle" color="#00BB9A" :percentage="item.rate"></el-progress>
					</div>
					<div class="box-content">
						{{item.title}}
					</div>
				</div> -->
			</div>
		</div>
	</div>
	<div class="middle-div">
		<span class="middle-div-title">蓝莓科技面向全国招商代理</span>
		<br/>
		<span class="middle-div-subtitle">千亿级市场期待与你共同开拓</span>
		<br>
		<el-button type="primary" class="proxy-btn" size="medium" @click="agent();" >我要代理</el-button>
	</div>
	<div class="qualification">
		<div class="qualifi-title">
			<span>企业资质</span>
			<div class="company-intro">广西南宁蓝莓网络科技有限公司(以下简称“蓝莓科技”)成立于2017年1月，是一家专注于互联网数据挖掘及互联网系统研发的高科技公司，致力于打造大中小企业主以及个体商户基于人工智能AI大数据的互联网营销整体解决方案。我们一直秉持着“让天下没有难做的销售”的企业价值观，帮助大中小企业主及个体商户以最低成本实现大数据营销。</div>
		</div>
		<div class="qualifi-content">
			<div class="qualifi-img" v-for="item in qualifiList" >
				<img :src="item.image" />
			</div>
		</div>
	</div>
	<div class="news">
		<div class="news-title">
			新闻动态
		</div>
		<div class="news-div">
			<div class="news-left">
				<img :src="fistNews.image" v-if="fistNews!=null && fistNews.image !=null" />
				<span class="news-f-title" @click="newsDetail(fistNews,1)">{{fistNews.title}}</span>
				<br>
				<div class="news-content-left-intro">
					{{fistNews.intro}}
				</div>
			</div>
			<div class="news-right">
				<div class="news-right-top">
					<div class="moving-lm">蓝莓动态</div>
					<div class="news-more">
						<span @click="newsMore()">更多动态>></span> 
					</div>
				</div>
				<ul>
					<li class="news-list" v-for="(item,index) in news" >
						<div class="news-img">
							 <img :src="item.image" />
						</div>
						<div class="news-content">
							<span class="news-item-title" @click="newsDetail(item,index)">{{item.title}}</span>
							<span class="news-time">{{item.createTime}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { Swiper, SwiperSlide,} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  metaInfo: {
	title: '蓝莓科技-AI智能大数据营销易拓客帮助个人和企业实现数字化营销提升销售业绩-广西南宁蓝莓网络科技有限公司',
	meta: [
	  {
		name: 'keywords',
		content: '蓝莓科技,广西南宁蓝莓网络科技有限公司，易拓客,外呼防封电话,高频不封号,智能销售,智能销售服务,智能销售解决方案,降低销售成本,人工智能销售,找客户,找客源,客源,客户,汇容客,慧抖销'
	  },
	  {
		name: 'description',
		content: '蓝莓科技(www.lanmeihulian.com)易拓客是国内专业的智能销售服务提供商。我们为企业和个人销售提供从线索挖掘、商机触达、客户管理到成单分析的全流程智能销售解决方案，帮助大中小型企业和个人在销售端降低销售成本，提升销售业绩'
	  }
	]
  },
  name: 'Home',
  components: {
    Header,
	Footer,
    Swiper,
    SwiperSlide
  },
  data () {
      return {
		swiperOption: {
			 loop: true, // 循环模式选项
			 autoplay: {
				 delay: 10000,//10秒切换一次 
				 stopOnLastSlide: false,
				 disableOnInteraction: false,
			 },//自动循环
			 spaceBetween: 30,
			 pagination: {
			  el: '.swiper-pagination',
			  clickable: true,
			  type:'bullets'
			},
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev'
			}
		},
		flag:'home',
		//轮播图
	    carousels: [],
		qrcodeVisible:false,
		business1:[
		  {title:"易拓客",
		  url:"/ytk",
		  intro:"全网各大平台公开数据采集，海量精准客源，从客源到成单一体系管理，让您销售更轻松！",
		  image:require("@/assets/img/img_1.png")
		  },
		  {
		  title:"电销外呼防封电话",
		  url:"/phone",
		  intro:"产品实现避开封卡标准，每天拨打电话不封号，在可控的范围内帮助用户实现销售高业绩。",
		  image:require("@/assets/img/img_2.png")
		  },
		  {
		  title:"短信群发营销",
		  url:"/message",
		  intro:"产品使用企业级 106 短信平台，支持短信营销、通知，并提供短信在线发送。",
		  image:require("@/assets/img/img_3.png")
		  }
		  ],
		business2:[
		  {
		  title:"智能名片",
		  url:"/ai",
		  intro:"智能名片代替传统名片，实现带流量的名片，AI+大数据+小程序，智能挖掘客户线索，实现流量变现。",
		  image:require("@/assets/img/img_4.png")
		  },
		  {
		  title:"拓客活动",
		  url:"/tk",
		  intro:"通过快速分享资源、数据信息、媒体链接，让用户得知产品相关活动，并根据活动选择相关项目，一定程度上实现推广、营销、销售等进度。",
		  image:require("@/assets/img/img_5.png")
		  },
		],
		reasons:[
			{
			title:"多方渠道，亿级客源海量数据，精准客户",
			intro:"支持三大地图数据、三大运营商数据、1.6亿工商数据、各大主流网站数据，覆盖全国主流数据源。数据真实有效，用户群体明确，客户资源齐全",
			image:require("@/assets/img/index_cp1.png")
			},
			{
			title:"多样触达，多元客户触达客户，高效便捷",
			intro:"支持智能网格模式，运用大数据触达客户，实现客户数据的信息化与可视化",
			image:require("@/assets/img/index_cp2.png")
			},
			{
			title:"推广营销，全套模式平台销售，提升业绩",
			intro:"支持PaaS的营销模式，提供平台与服务，通过模式识别推荐引擎与销售手段，从而提高销售业绩",
			image:require("@/assets/img/index_cp3.png")
			},
			{
			title:"产品内联，数据互通信息交互，实时同步",
			intro:"支持产品互联，旗下产品实现数据调用、数据传输、数据交互等，总体实现一键互联，产品内联实时同步相关数据，形成完整结构体系",
			image:require("@/assets/img/index_cp4.png")
			},
			{
			title:"海量客户，全国维护资源共享，数据更新",
			intro:"支持客户数据实时更新，维护客户真实信息；确保客户资源的真实性与有效性",
			image:require("@/assets/img/index_cp5.png")
			},
			{
			title:"产品推出，软件安装升级更新，售后无忧",
			intro:"产品上线，在苹果、安卓等主流应用市场均有上架，运营稳定；支持每个客户实现一对一专属顾问，提供专业服务，产品有保证，售后有保障",
			image:require("@/assets/img/index_cp6.png")
			},
		],
		//用户案例
		evaluation:[],
		//销售业绩
		// performs:[],
		//企业资质
		qualifiList:[],
		//新闻动态
		news: [],
		fistNews:{
			image: "",
			title: "",
			intro: "",
			id: null
		}
	  }
  },
  created(){
	  this.getBanner();
  	  this.getEvaluation();
  	// this.getSalesList();
  	  this.getQualificationList();
  	  this.getNewsList();
  },
  methods:{
		getBanner(){
			this.$http.getBannerList({type:1}).then(res => {
				if(res.data!=null){
					this.carousels = res.data;
				}
			})
		},
		caseMore(){
			this.$router.push({path:'/case',name:'CustomCase',query:{type:0}});
		},
		newsMore(){
			this.$router.push({path:'/news',name:'News'});
		},
		newsDetail(item,index){
			if(item.link != null && item.link !=''){
				window.open(item.link, '_blank');
			}else{
				this.$router.push({path:'/newsDetail',name:'NewsDetail',query:{id:item.id,t:Date.now()}});
			}
		},
		agent(){
			this.$router.push({path:'/agent',name:'Agent'});
		},
		goToYTK(){
			this.$router.push({path:'/tk',name:'TkAction'});
		},
		goToAi(){
			this.$router.push({path:'/ai',name:'Intelligent'});
		},
		getEvaluation(){
			this.$http.getIndexCaseList().then(res => {
				if(res.data!=null){
					this.evaluation = res.data;
				}
			})  
		},
		businessSelect(path) {
			this.$router.push({path:path,query:{t:Date.now()}});
		},
		// getSalesList(){
		// 	this.$http.getSalesList().then(res => {
		// 		if(res.data!=null){
		// 			this.performs = res.data;
		// 		}
		// 	})  
		// },
		getQualificationList(){
			this.$http.getQualificationList().then(res => {
				if(res.data!=null){
					this.qualifiList = res.data;
				}
			})  
		},
		getNewsList(){
		  this.$http.getIndexNewsList().then(res => {
			if(res.data!=null){
				this.news = res.data;
				if(this.news.length>1){
					this.fistNews.image=this.news[0].image;
					this.fistNews.title=this.news[0].title;
					this.fistNews.intro=this.news[0].intro;
					this.fistNews.id=this.news[0].id;
					this.news.shift();
				}
			}
		  })  
		}
	  }
}
</script>

<style scoped lang="less">
	.ytk-qr-code{
		text-align: center;
		img{
			width:100%;
			height: 100%;
		}
	}
	
	
	.banner-banner{
		overflow: hidden;
		.swiper {
		  height: 576px;
		  width: 100%;
		  .swiper-slide {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				font-weight: bold;
				font-size: 14px;
			}
		}
		.ytk-go-div{
			position: absolute;
			left: 250px;
			top:450px;
			width:400px;
			z-index: 1000;
			cursor: pointer;
			.ykt-text{
				color: #fff;
				font-size: 24px;
				letter-spacing: 5px;
				font-family:"bradley hand itc";
			}
			.ykt-text:hover{
				color:#B90F10;
			}
		}
	}
	.home{
		background-color: #f5f6fa;
		.top-content{
			width: 1200px;
			height: auto !important;
			margin: 30px auto;
			.top-div{
				display: -webkit-flex; /* Safari */
			    display: flex;
				flex-direction:row;
				justify-content:center;
				.top-div-left,.top-div-right{
				  height: 450px;	
				}
				.top-div-left{
					width: 750px;
					.top-left-title{
						font-size: 30px;
						color: #131313;
						display: inline-block;
						margin-top: 60px;
						margin-bottom: 20px;
					}
					.top-left-subtitle{
						font-size: 16px;
						color: #666666;
						font-family: "microsoft yahei";
						display: inline-block;
						margin-bottom: 40px;
					}
					.el-button{
						font-size: 20px !important;
						line-height: 30px;
					}
					.btn_ty{
						height: 48px;
						width: 160px;
						color: #FFFFFF;
						span{
							font-size: 16px;
						}
					}
				}
				.top-div-right{
					width: 350px;
					 img{
						width: 230px;
						 height: 450px;
					 }
				}	
			}
		}
		.server-div{
			width: 1200px;
			height: auto;
			margin: 40px auto;
			margin-top: 78px;
			.server-div-top{
				background-image: url(../assets/img/SERVERS.png);
				background-repeat: no-repeat;
				height: 100px;
				width: 320px;
				margin: auto;
				text-align: center;
				.server-title{
					padding-top: 8px;
					padding-bottom: 14px;
					font-size: 36px;
					font-weight: 600;
					color: #131313;
					display: inline-block;
				}
				.server-border{
					width: 100px;
					background-color: #409EFF;
					height: 6px;
					border-radius: 5px;
					margin: auto;
				}
			}
			.server-div-middle{
				width: 1200px;
				text-align:center;
				.middle-title{
					display: inline-block;
					text-align: left;
					width: 1100px;
					height: 80px;
					font-size: 18px;
					color: #666666;
					line-height: 30px;
				}
				.server-middle{
					margin-top: 63px;
					display: flex;
					display: -webkit-flex; /* Safari */
					flex-direction:row;
					justify-content:center;
					.server-middle-left,.server-middle-right{
						width: 600px;
						text-align: center;
						.text{
							margin-top: 34px;
							text-align: center;
							.text-s{
								font-size: 16px;
								line-height: 20px;
								color: #131313;
							}
							.text-style{
								font-size: 38px;
								line-height: 80px;
								font-weight: 600;
								color: #217bfb;
							}
						}
					}
				}
			}
		}
		.main{
			width: 1200px;
			height: auto;
			margin: 40px auto;
			.main-div1{
				text-align: center;
				height: 800px;
				display: -webkit-flex; /* Safari */
				display: flex;
				flex-direction:column;
				justify-content:center;
				.title{
					height: 150px;
					text-align: center;
					margin-top: 10px;
					margin-bottom: 40px;
					.title-lm{
						font-size: 30px;
						color: #131313;
						line-height: 80px;
					}
					.title-text{
						width: 600px;
						font-size: 16px;
						line-height: 30px;
						color: #666666;
						margin: auto;
					}
				}
				.images-list{
					height: 261px;
					display: -webkit-flex; /* Safari */
					display: flex;
					flex-direction:row;
					justify-content:center;
					.b-box{
						width: 327px;
						height: 261px;
						.b-box-title{
							margin-top: 30px;
							.title{
								font-size: 24px;
								color: #ffffff;
								font-weight: 600;
								line-height: 60px;
							}
							.b-box-border{
								width: 45px;
								background-color: #ffffff;
								height: 4px;
								border-radius: 5px;
								margin: auto;
							}
						}
						.b-box-content{
							margin-top: 28px;
							width: 260px;
							display: inline-block;
							font-size: 16px;
							color: #ffffff;
							line-height: 25px;
						}
					}
					.l-box{
						width: 490px;
						height: 261px;
						.b-box-title{
							margin-top: 30px;
							.title{
								font-size: 24px;
								color: #ffffff;
								font-weight: 600;
								line-height: 60px;
							}
							.b-box-border{
								width: 45px;
								background-color: #ffffff;
								height: 4px;
								border-radius: 5px;
								margin: auto;
							}
						}
						.b-box-content{
							margin-top: 28px;
							width: 360px;
							display: inline-block;
							font-size: 16px;
							color: #ffffff;
							line-height: 25px;
						}
					}
					.b-box:hover{
						cursor: pointer;
						.b-box-title{
							.title{
								color: #4DA2FC;
							}	
						}
					}	
					.l-box:hover{
						cursor: pointer;
						.b-box-title{
							.title{
								color: #4DA2FC;
							}	
						}
					}	
				}
							
			}
			.main-div2{
				margin-top: 97px;
				text-align: center;
				height: 650px;
				.title{
					margin: auto;
					width: 900px;
					font-size: 30px;
					line-height: 55px;
					color: #131313;
				}
				.ti-div{
					margin-left: 70px;
					margin-top: 40px;
					.ti-product-01{
						margin-left: 30px;
						height: 132px;
						display: -webkit-flex; /* Safari */
						display: flex;
						.ti-title-01{
							width: 498px;
							height:122px;  
							background-image: url(../assets/img/ty_1.png);
							background-repeat: no-repeat;
							span{
								position: relative;
								top: 50px;
								color: white;
							}
							.ti-title{
								font-size: 20px;
							}
							.ti-text{
								font-size: 16px;
							}
						}
						.ti-centent-01,.ti-centent-02,.ti-centent-03,.ti-centent-04{
							width: 500px;
							height: 70px;
							text-align: left;
							span{
								width: 470px;
								height: 75px;
								display: inline-block;
								padding-left: 35px;
								padding-right: 15px;
								line-height: 35px;
								padding-top: 20px;
								color: #131313;
								font-size: 16px;
							}
						}
						.ti-title-02{
							width: 397px;
							height:122px;  
							margin-left: 48px;
							background-image: url(../assets/img/ty_2.png);
							background-repeat: no-repeat;
							span{
								position: relative;
								top: 50px;
								color: white;
							}
							.ti-title{
								font-size: 20px;
							}
							.ti-text{
								font-size: 16px;
							}
						}
						.ti-centent-02{
							span{
								padding-left: 85px;
							}
						}
						.ti-title-03{
							width: 299px;
							height:122px;  
							margin-left: 100px;
							background-image: url(../assets/img/ty_3.png);
							background-repeat: no-repeat;
							span{
								position: relative;
								top: 55px;
								left: -10px;
								color: white;
							}
							.ti-title{
								font-size: 20px;
							}
							.ti-text{
								font-size: 16px;
							}
						}
						.ti-centent-03{
							span{
								padding-left: 125px;
							}
						}
						.ti-title-04{
							width: 195px;
							height:122px;  
							margin-left: 150px;
							background-image: url(../assets/img/ty_4.png);
							background-repeat: no-repeat;
							span{
								position: relative;
								top: 55px;
								left: -10px;
								color: white;
							}
							.ti-title{
								font-size: 20px;
							}
							.ti-text{
								font-size: 16px;
							}
						}
						.ti-centent-04{
							span{
								height: 50px;
								padding-top: 45px;
								padding-left: 178px;
							}
						}
					}
				}
			}
		}	
		.sales-div1{
			background-color: #252525;
			height: 900px;
			margin-top: 120px;
			padding-top: 30px;
			.sales-div1-content{
				height: 900px;
				max-width: 1920px;
				margin: auto;
				background-image: url(../assets/img/index_bg1.png);
				background-repeat: no-repeat;
				.content-left{
					margin: auto;
					width: 1200px;
					padding-top: 40px;
					color: #ffffff;
					.sales-title{
						width: 1200px;
						display: inline-block;
						font-size: 38px;
						text-align: center;
					}
					.sales-content{
						height: 550px;
						padding: 20px 30px 10px 0;
						line-height: 35px;
						font-size: 16px;
					}
					.buttom-btn{
						padding-left: 190px;
						.btn1,.btn2{
							display: inline-block;
							height: 80px;
							.btn-style{
								width: 150px;
								height: 50px;
								font-size: 20px;
							}
						}
					}
				}
			}
		}
		.sales-div{
			width: 1200px;			margin: auto;
			height: auto;
			.select-us{
				margin-top: 152px;
				height: 800px;
				text-align: center;
				.select-us-title{
					background-image: url(../assets/img/FONT_REASONS.png);
					background-repeat: no-repeat;
					height: 80px;
					width: 326px;
					margin: auto;
					text-align: center;
					font-size: 38px;
					color: #131313;
					font-weight: 600;
				}
				.select-us-content{
					margin-top: 50px;
					display: -webkit-flex; /* Safari */
					display: flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content:center;
					.reason-box{
						width: 330px;
						height: 280px;
						background-color: #F2F2F2;
						padding: 10px 10px 10px 10px;
						margin:0px 10px 20px 10px;
						.reason-box-top{
							margin-top: 50px;
							display: -webkit-flex; /* Safari */
							display: flex;
							flex-direction:row;
							.reason-box-img{
								width: 100px;
								img{
									width: 60px;
									height: 60px;
								}
							}	
							.reason-title{
								text-align: center;
								.title{
									display: inline-block;
									width: 190px;
									color: #131313;
									font-size: 20px;
									font-weight: 600;
									line-height: 30px;
									text-align: left;
								}
							}
							.reason-title:hover{
								cursor: pointer;
								.title{
									color: #4DA2FC;
								}
							}
						}
						.reason-intro{
							margin: auto;
							width: 300px;
							text-align: left;
							margin-top: 20px;
							padding-top: 10px;
							font-size: 16px;
						}
					}
					.reason-box:hover{
						
						box-shadow: 10px 10px 10px gray;
						
					}
				}
			}
			.evaluation{
				height: auto;
				margin-top: 76px;
				margin-bottom: 20px;
				text-align: center;
				.evaluate-title{
					.title{
						font-size: 38px;
						line-height: 70px;
						color: #131313;
					}
					.subtitle{
						font-size: 20px;
						color: #666666;
					}
				}
				.more{
					margin: auto;
					width: 180px;
					margin-top: 20px;
					height: 80px;
					text-align: center;
					font-size: 16px;
					color: #131313;
					line-height: 25px;
					font-weight: 600;
					padding-right: 20px;
					.more-btn{
						width: 180px;
						height: 60px;
						background-color: rgb(240,240,240);
						color: #131313;
						font-size: 18px;
					}
				}	
				.more:hover{
					cursor: pointer;
					.more-btn{
						color: #217bfb;
					}
				}
				.evaluate-content{
					margin-top: 46px;
					text-align: center;
					display: -webkit-flex;
					display: flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content:center;
					.review-box{
						width: 280px;
						height: auto;
						box-shadow: 5px 5px 5px #DCDCDC;
						margin:0px 8px 20px 8px;
						.review-img{
							img{
								width: 280px;
							}	
						}
						.review-title{
							margin-top: 30px;
							
							padding: 5px;
							font-size: 24px;
							color: #217bfb;
							line-height: 35px;
							white-space: nowrap;  /*强制span不换行*/
							display: inline-block;  /*将span当做块级元素对待*/
							width: 240px; /*限制宽度*/
							overflow: hidden;  /*超出宽度部分隐藏*/
							text-overflow: ellipsis;  /*超出部分以点号代替*/
						}
						.review-intro{
							width: 250px;
							display: inline-block;
							margin-bottom: 30px;
							padding-top: 20px;
							line-height: 25px;
							font-size: 16px;
							color: #666666;
							
						}
					}
					.review-box:hover{
						box-shadow:silver 0px 0px 10px;
						.review-title{
							color: #1756A3;
						}
					}
				}
				
			}
		
			.perform{
				height: 550px;
				margin-top: 100px;
				text-align: center;
				.perform-title{
					font-size: 36px;
					color: #131313;
					font-weight: 600;
				}
				.perform-content{
					margin-top: 70px;
					text-align: center;
					.perform-item{
						height: 50px;
						padding-top: 20px;
						.perform-item-ul{
							display: -webkit-flex;
							display: flex;
							flex-direction:row;
							flex-wrap:wrap;
							justify-content:center;
							li{
								text-align: center;
								font-size: 20px;
								color: #131313;
								width: 155px;	
							}
						}
					}	
					
				}	
			}
		}
		.middle-div{
			height: 350px;
			background-image: url(../assets/img/ic_zsjm.png);
			background-size: cover;
			text-align: center;
			color: white;
			padding-top: 50px;
			.middle-div-title{
				font-size: 36px;
				font-weight: 600;
				line-height: 80px;
			}
			.middle-div-subtitle{
				font-size: 36px;
				line-height: 60px;
				font-weight: 600;
			}
			.proxy-btn{
				margin-top: 30px;
				line-height: 20px;
				width: 260px;
				height: 100px;
				border-radius: 20px;
				font-weight: 600;
				background-color: #ffffff;
				color: #217bfb;
				font-size: 24px;
			}
		}
		.qualification{
			text-align: center;
			width: 1200px;
			height: auto;
			margin: auto;
			.qualifi-title{
				margin-top: 86px;
				span{
					font-size: 36px;
					color: #131313;
					font-weight: 600;
				}
				.company-intro{
					padding-top: 40px;
					width: 1100px;
					text-align: center;
					margin: auto;
					line-height: 30px;
					font-size: 18px;
					color: #131313;
					margin-bottom: 40px;
				}	
			}
			.qualifi-content{
				display: flex;
				display: -webkit-flex;
				flex-direction:row;
				flex-wrap:wrap;
				justify-content:center;
				.qualifi-img{
					img{
						width: 300px;
						height: 200px;
					}
					box-shadow: 5px 5px 5px #DCDCDC;
					margin: 10px;
				}
				margin-bottom: 40px;
			}
		}
		.news{
			width: 1200px;
			height: auto;
			margin: auto;
			margin-top: 130px;
			margin-bottom: 40px;
			.news-title{
				background-image: url(../assets/img/FONT_NEWS.png);
				background-repeat: no-repeat;
				width: 186px;
				margin: auto;
				text-align: center;
				line-height: 50px;
				font-size: 36px;
				color: #131313;
				font-weight: 600;
			}
			.news-div{
				margin-top: 70px;
				display: -webkit-flex; /* Safari */
				display: flex;
				flex-direction:row;
				flex-wrap:wrap;
				justify-content:center;
				.news-left{
					width: 500px;
					height: 500px;
					text-align: center;
					img{
						width: 500px;
						height: 300px;
					}
					.news-f-title{
						padding-top: 20px;
						color: #131313;
						font-size: 20px;
						font-weight: 600;
						white-space: nowrap;  /*强制span不换行*/
						display: inline-block;  /*将span当做块级元素对待*/
						width: 360px;  /*限制宽度*/
						overflow: hidden;  /*超出宽度部分隐藏*/
						text-overflow: ellipsis;  /*超出部分以点号代替*/
						line-height: 0.9;  /*数字与之前的文字对齐*/
					}
					.news-f-title:hover{
						color: #B90F10;
						cursor: pointer;
					}
					.news-content-left-intro{
						text-align:left;
						font-size: 16px;
						padding: 15px;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						-o-text-overflow: ellipsis;/* 支持 Opera */ 
						 overflow: hidden;
					}
				}
				.news-right{
					padding-left: 30px;
					width: 650px;
					height: 500px;
					.news-right-top{
						height: 40px;
						width: 650px;
						margin-bottom: 20px;
						border-bottom: 1px solid #DCDCDC;
						display: -webkit-flex; /* Safari */
						display: flex;
						flex-direction:row;
						justify-content:space-between;
						.moving-lm{
							font-size: 20px;
							color: #131313;
							font-weight: 600;
						}	
						.news-more{
							text-align: right;
							font-size: 18px;
							color: #131313;
							line-height: 35px;
							font-weight: 600;
							padding-right: 20px;
						}
						.news-more:hover{
							cursor: pointer;
							color: #1756A3;
						}
					}
					.news-list{
						margin-top: 10px;
						width: 650px;
						max-height: 100px;
						height: auto!important;
						display: flex;
						display: -webkit-flex;
						flex-direction: row;
						// border-bottom: 1px solid #DCDCDC;
						.news-img{
							width: 80px;
							height: 60px;
							img{
								width: 60px;
								height: 60px;
							}
						}
						.news-content{
							display: inline-block;
							vertical-align: top;
							position:relative;
							height: 70px;
							width: 600px;
							margin-left: 24px;
							.news-item-title{
								text-align: left;
								height: 35px;
								width: 600px;
								display: inline-block;
								color: #131313;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								// line-height: 28px;
								font-size: 18px;
								letter-spacing: 0;
								font-weight: 500;
								transition: color .2s;
							}
							.news-item-title:hover{
								cursor: pointer;
								color: #B90F10;
							}
							.news-time{
								display: inline-block;
								text-align: left;
								font-size: 16px;
								color: #999999;
								// height: 22px;
								letter-spacing: 0;
								line-height: 22px;
								font-weight: 400;
								position:relative;
								bottom:0;
							}
						}
					}
				}
			}
		}
	}
	
</style>