import axios from 'axios';
import qs from 'qs';
import config from '@/config'
import {Message} from 'element-ui'

//请求地址
const baseUrl = process.env.NODE_ENV ==='development' ? config.baseUrl.dev : config.baseUrl.prod
axios.defaults.baseURL = baseUrl;
//响应时间单位（毫秒）60s
axios.defaults.timeout = 60000;

// 请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//请求拦截器
axios.interceptors.request.use((config) => {
	if (config.method === 'post') {
	  config.data = qs.stringify(config.data);
	}
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截器
axios.interceptors.response.use((res) => {
		const code = res.data.code || 0;
		// 获取错误信息
		// const msg = errorCode[code] || res.data.msg || errorCode['default']
		const msg = res.data.msg
		if (code === 500) {
		  Message({
		    message: msg,
		    type: 'error'
		  })
		  return Promise.reject(new Error(msg))
		} else if (code !== 0) {
		  Notification.error({
		    title: msg
		  })
		  return Promise.reject('error')
		} else {
		  return res.data
		}
    },
    (error) => {
        // Message.error("请求超时！",);
        return Promise.reject(error);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {

  return axios({
    url: url,
    method: 'get',
    params: params
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
export function post(url, data = {}) {
  return axios({
    url: url,
    method: 'post',
    data: data
  });
}
