import {
    post,
    get
} from './request.js';

/**
 * 首页    获取轮播图
 */
export const getBannerList = (params) => {
    return get('/app/banner/bannerList',params);
}

/**
 * 首页    获取用户案例
 */
export const getIndexCaseList = () => {
    return get('/app/case/indexCaseList');
}


/**
 * 首页    获取销售业绩
 */
export const getSalesList = () => {
    return get('/app/performance/salesList');
}


/**
 * 首页    获取企业资质
 */
export const getQualificationList = () => {
    return get('/app/qualification/qualificationList');
}

/**
 * 首页    获取新闻动态
 */
export const getIndexNewsList = () => {
    return get('/app/news/indexNewsList');
}


/**
 * 客户案例    获取用户案例
 */
export const getCaseList = (params) => {
    return get('/app/case/caseList',params);
}

/**
 * 新闻动态    获取新闻动态
 */
export const getNewsList = (params) => {
    return get('/app/news/newsList',params);
}

/**
 * 新闻动态    获取推荐新闻列表
 */
export const getRecommendNewsList = (params) => {
    return get('/app/news/recommendNewsList',params);
}

/**
 * 新闻动态    获取新闻详情
 */
export const getNewsDetail = (id) => {
    return get('/app/news/newsDetail/'+id);
}

/**
 * 适用行业    获取适用行业
 */
export const getIndustryList = (params) => {
    return get('/app/industry/industryList',params);
}

/**
 *     获取公司发展历程
 */
export const getCoursesList = () => {
    return get('/app/course/coursesList');
}

/**
 *     获取关于我们
 */
export const getCompanyParam = () => {
    return get('/app/param/getCompanyParam');
}

/**
 *     获取公司介绍
 */
export const getCompanyIntro = () => {
    return get('/app/help/getCompanyIntro');
}

/**
 *     添加招生加盟
 */
export const addAgent = (data) => {
    return post('/app/agent/add',data);
}

/**
 * 加入我们    获取员工福利
 */
export const getWelfareList = () => {
    return get('/app/position/welfareList');
}

/**
 * 加入我们    获取职位分类列表
 */
export const getPositionTypeList = () => {
    return get('/app/position/positionTypeList');
}

/**
 * 加入我们    获取职位列表
 */
export const getPositionList = (params) => {
    return get('/app/position/positionList',params);
}

/**
 * API平台    获取接口分类
 */
export const getTypeList = () => {
    return get('/api/company/getTypeList');
}

/**
 * API平台    根据分类id查询接口列表
 */
export const getApiList = (params) => {
    return get('/api/company/getApiListByType',params);
}

/**
 * API平台    根据接口id查询接口详情
 */
export const getApiBasic = (params) => {
    return get('/api/company/getApiBasic',params);
}