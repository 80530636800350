import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ytk from '../views/Ytk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ytk',
    name: 'Ytk',
    component: Ytk
  },
  {
    path: '/phone',
    name: 'Phone',
    component: () => import('@/views/Phone.vue')
  },
  {
    path: '/message',
    name: 'Message',
   component: () => import('@/views/Message.vue')
  },
  {
    path: '/ai',
    name: 'Intelligent',
   component: () => import('@/views/Intelligent.vue')
  },
  {
	 path: '/red',
	 name: 'Fission',
	 component: () => import('@/views/Fission.vue')
  },
  {
    path: '/tk',
    name: 'TkAction',
   component: () => import('@/views/TkAction.vue')
  },
  {
    path: '/case',
    name: 'CustomCase',
   component: () => import('@/views/CustomCase.vue')
  },
  {
    path: '/news',
    name: 'News',
   component: () => import('@/views/News.vue')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
   component: () => import('@/views/NewsDetail.vue')
  },
  {
    path: '/company',
    name: 'Company',
   component: () => import('@/views/Company.vue')
  },
  {
    path: '/agent',
    name: 'Agent',
   component: () => import('@/views/Agent.vue')
  },
  {
    path: '/about',
    name: 'AboutUs',
   component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
   component: () => import('@/views/JoinUs.vue')
  },
  {
    path: '/cloudApi',
    name: 'CloudApi',
   component: () => import('@/views/CloudApi.vue')
  },
  {
    path: '/ys',
    name: 'Ys ',
   component: () => import('@/views/Ys.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    next();
});

export default router
